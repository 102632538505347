.white-transition-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*
  animation: pulse 1s infinite alternate;
  animation: grow 1.5s ease-in-out;
  width: auto;
  height: 100%;
*/
.transition-logo {
  transition: transform 1.5s ease-in-out;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.white-transition-enter {
  opacity: 0;
}

.white-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.white-transition-exit {
  opacity: 1;
}

.white-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
