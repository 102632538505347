.kj-node__header {
  text-align: center;
}

.kj-node__content {
  font-size: 18px;
}

.kj-node__content p {
  background-color: rgba(210, 230, 255, 0.7);
  margin: 5px 0;
  padding: 5px;
  border-radius: 5px;
}
