/*
* Initialization
*/

* {
  margin: 0;
  padding: 0;
}

/*
* Debug purpose
*/

.result-task-id {
  display: none;
  padding: 10px;
}

/*
* Global styles
*/

body {
  font-family: sans-serif;
}

header, footer {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  padding: 8px 0;
  z-index: 999;
}

header {
  top: 0;
}

footer {
  bottom: 0;
  display: flex;
  justify-content: center;
}

footer ul {
  display: flex;
  list-style: none;
  gap: 10px;
  margin: 0 10px;
}

footer a {
  color: white;
  text-decoration: none;
}

footer #terms-and-privacy {
  cursor: pointer;
  font-size: 0.7rem;
  padding: 5px 0;
}

.background-canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-content {
  z-index: 1;
}

/*
* Button styles
*/

.control-panel-item button,
.copy-clipboard-button-container button,
.save-mindmap-container button,
.save-class-diagram-container button {
  background-color: transparent;
  color: white;
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
}

.control-panel-item button:hover,
.copy-clipboard-button-container button:hover,
.save-mindmap-container button:hover,
.save-class-diagram-container button:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.input-start-button,
.result-reset-button {
  text-align: center;
}

#start-button {
  margin: 10px 0;
}

.copy-clipboard-button-container,
.save-mindmap-container {
  /* see also responsive style at bottom */
  display: block;
  text-align: center;
}

/*
* Icon styles
*/

.icon-x,
.icon-github,
.icon-linkedin {
  fill: white;
  vertical-align: middle;
}

/*
* Terms and Policy Dialog styles
*/

.MuiDialogContent-root h2,
.MuiDialogContent-root p {
  margin: 5px 0 !important;
}

.MuiDialogContent-root ul {
  padding-left: 15px !important;
}

/*
* Input Dialog Box styles
*/

.input-dialog-theme,
.input-dialog-ideas,
.input-dialog-freedom {
  font-size: 14px;
  margin-top: 2px;
}

/*
* Reset Dialog Box styles
*/

#alert-dialog-description {
  color: red;
  font-weight: bold;
  margin-bottom: 10px;
}

/*
* Input screen styles
*/

.control-panel {
  /* see also responsive style at bottom */
  color: white;
  padding-bottom: 40px;
}

.control-panel-item {
  background-color: rgba(128, 128, 128, 0.3);
  padding: 10px 15px;
  border-radius: 10px;
}

.control-panel-item div {
  margin-top: 10px;
}

.banner-input img {
  max-width: 100%;
}

.label-usage p {
  margin-top: 5px;
}

#prompt {
  font-size: 24px;
  text-align: center;
  width: 100%;
}

.input-slider {
  background-color: white;
  height: 3px;
  border-radius: 2px;
  margin-right: 5px;
  -webkit-appearance: none;
}

.input-slider::-webkit-slider-thumb {
  background-color: white;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

/*
* Result screen styles
*/

.result-screen {
  color: white;
  position: absolute;
}

/* Sidebar */

.result-sidebar {
  /* see also responsive style at bottom */
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 15px;
}

.banner-result,
.result-menu {
  padding: 20px 10px;
}

.banner-result img {
  width: 100%;
}

#menu-title {
  font-weight: bold;
  text-align: center;
}

.result-menu-list {
  list-style: none;
}

.result-menu-list li {
  margin: 10px 0;
}

.result-reset-button {
  margin: 10px;
}

/* Main */

.result-main {
  /* see also responsive style at bottom */
  background-color: rgba(128, 128, 128, 0.3);
  border-radius: 15px;
}

.progress-bar {
  display: flex;
  background-color: rgba(64, 64, 64, 0.7);
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.progress-bar span {
  color: white;
  font-size: 28px;
  font-weight: bold;
  margin-left: 10px;
}

#elapsed-time {
  color: yellow;
}

.result-main h1 {
  font-size: 24px;
  text-align: center;
  margin: 10px 0;
}

/* MarkdownCanvas */

.markdown-canvas {
  /* see also responsive style at bottom */
}

.markdown-column {
  /* see also responsive style at bottom */
  background-color: rgba(64, 64, 64, 0.7);
  margin: 5px 1px;
  padding: 10px;
  border-radius: 8px;
}

/* MindmapCanvas */

.mindmap-canvas {
  /* see also responsive style at bottom */
}

.vis-button {
  background-color: white;
}

/* ClassDiagramCanvas */

.class-diagram-canvas {
  /* see also responsive style at bottom */
}

.react-flow__controls-button {
  color: black !important;
}

.react-flow__attribution {
  display: none;
}

.react-flow__node {
  background-color: #97c2fc !important;
  color: #343434;
  width: 300px !important;
  padding: 10px;
  border: 1px solid #316bba;
  border-radius: 10px;
}

/*
* Responsive for PC and Tablet
*/

@media screen and (min-width: 601px) {
  .control-panel {
    position: absolute;
    top: 28%;
    left: 50%;
    width: 600px;
    transform: translate(-50%, -30%);
  }

  .result-screen {
    width: 100%;
  }

  .result-sidebar {
    position: absolute;
    left: 1%;
    width: 21%;
    height: 100%;
    overflow-y: auto;
  }

  .result-main {
    position: absolute;
    left: 23%;
    width: 76%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .markdown-canvas {
    display: flex;
    justify-content: space-between;
  }

  .markdown-column {
    flex: 1;
  }

  .mindmap-canvas,
  .class-diagram-canvas {
    height: 660px;
  }
}

/*
* Responsive for Smartphone
*/

@media screen and (max-width: 600px) {
  .control-panel {
    position: absolute;
    margin-top: 50px;
    width: 100%;
    overflow-y: auto;
  }

  .result-screen {
    overflow-y: auto;
  }

  .result-sidebar {
    position: relative;
    width: 100%;
  }

  .result-main {
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .markdown-canvas {
  }

  .markdown-column {
  }

  .mindmap-canvas,
  .class-diagram-canvas {
    height: 400px;
  }
}
